import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

export default function ServicePage() {
    return (
        <Layout>
            <div id='ServicePage_page'>
                <div id='ServicePage_header'>
                    <div>
                        <h1 id='ServicePage_header--title'>OUR<br />SERVICES</h1>
                        <p id='ServicePage_header--subtitle'>We provide fundamental solutions to help grow your business by fulfilling all of your digital needs.</p>
                    </div>
                    <img id='ServicePage_header--img' alt='gearsimg.png' src='https://i.imgur.com/A8NeCLh.png'></img>
                </div>
                <div id='servicePage_SEO--container' className='ServicePage_container'>
                    <img id='ServicePage_SEO--img' alt='seoimg.png' src='https://i.imgur.com/iQ54IGr.png' ></img>
                    <div id='ServicePage_SEO--service' className='ServicePage_service'>
                        <h3 id='ServicePage_SEO--title'>Website Design</h3>
                        <Link to='/WebDesign'>
                            <button className='ServicePage_button'>CLICK HERE</button>
                        </Link>
                    </div>
                </div>
                <img id='ServicePage_background--img' alt='background.png' src='https://i.imgur.com/B9fpfyg.png'></img>
                <div id='servicePage_webDesign--container' className='ServicePage_container'>
                    <div id='ServicePage_websiteDesign--service' className='ServicePage_service'>
                        <h3 id='ServicePage_websiteDesign--title'>Search Engine<br />Optimization</h3>
                        <Link to='/SEO/'>
                            <button className='ServicePage_button'>CLICK HERE</button>
                        </Link>
                    </div>
                    <img id='ServicePage_websiteDesign--img' alt='webdesign.png' src='https://i.imgur.com/kstrt0d.png'></img>
                </div>
                <div id='servicePage_digitalMarketing--container' className='ServicePage_container'>
                    <img id='ServicePage_digitalMarketing--img' alt='digitalmarketing.png' src='https://i.imgur.com/4W7twm3.png'></img>
                    <div id='ServicePage_digitalMarketing--service' className='ServicePage_service'>
                        <h3 id='ServicePage_digitalMarketing--title'>Digital Marketing</h3>
                        <Link to='/DigitalMarketing/'>
                            <button className='ServicePage_button'>CLICK HERE</button>
                        </Link>
                    </div>
                </div>
            </div>
        </Layout>
    )
}